import React, { useEffect, useState, useRef } from "react";
import {
    Navbar,
    Button,
    Tabs,
    Modal,
    Drawer,
    CopyTextButton,
    Field,
    Select,
    TableSkeleton,
    Calendar,
} from "../../components";
import { motion } from "framer-motion";
import { useGlobalContext } from "../../context";
import localData from "../../localData";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { coy } from "react-syntax-highlighter/dist/esm/styles/prism";
import { Link, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import ReactDOMServer from "react-dom/server";
// import TrackAnEventContent from "./tabs/track-an-event/TrackAnEventContent";
// import TrackAndThankYouContent from "./tabs/track-and-thank-you/TrackAndThankYouContent";
import useFormatter from "../../hooks/useFormatter";
import TrackingDrawer from "./tracking-drawer/TrackingDrawer";
import useFetch from "../../hooks/useFetch";
import Joyride, { STATUS } from "react-joyride";
import { Tooltip } from "react-tooltip";

const {
    edit,
    question,
    password,
    support,
    userShield,
    ellipsisVeritcal,
    arrowRight,
    penV2,
    textPreloader,
    BloomingImage,
    CodeType,
    trashV2,
} = localData.svgs;
const { avatar, gtm, nextjs, html, nuxt, wordpress, pixel } = localData.images;

export default function Tracking() {
    useEffect(() => {
        document.title = "Tracking";

        const metaDescription = document.querySelector('meta[name="description"]');
        metaDescription.content =
            "Monitor your progress and stay updated with real-time data on your activities.";
    }, []);
    const { fade } = useGlobalContext().animations;

    const {plus} = localData.svgs

    const navigate = useNavigate()

    const {
        requestedData,
        successAlert,
        errorAlert,
        getAdvertiserPixelStatsRequest,
        conversionOptions,
        setConversionOptions,
        successAlertExpanded,
        warningAlertExpanded,
        requestedData: {
            userProfileData: { currentOrganization },
            campaignData: { currentOrganizationCampaigns },
        },
        isLoading,
        trackingState,
        campaignsState,
    } = useGlobalContext();

    const { campaignSelectionRange, setCampaignSelectionRange, handleCalendar} = campaignsState;

    const { setDisplayInstallPixel, setDefaultTab, setIsEditingMode, setEditingTagId, setState } =
    trackingState;

    const sendRequest = () => {
        getAdvertiserPixelStatsRequest();
    };

    useEffect(() => {
        if (!currentOrganization.id) return;
        getAdvertiserPixelStatsRequest();
    }, [currentOrganization.id]);

    const [isFirstLoad, setIsFirstLoad] = useState(true);

    useEffect(() => {
        if (isFirstLoad) {
            setIsFirstLoad(false);
            return;
        }

        if (!conversionOptions.advertiserPixelStats.value.length) {
            warningAlertExpanded(
                `You haven't created any conversions yet. Navigate to "Tracking" to setup Goal Tracking on your website.`,
                { toastId: "toast-tracking-alert" }
            );
        }
    }, [conversionOptions.advertiserPixelStats.value]);

    return (
        <motion.main {...fade} className="main tracking-page">
            <TrackingJoyride />
            {/* <Navbar title="Tracking" /> */}
            <div className="row tracking-drawer-container">
                {/* <Calendar
                    className="tracking-calendar"
                    _selectionRange={campaignSelectionRange}
                    callback={(range) => {
                        handleCalendar(range);
                        sendRequest();
                    }}
                    disabled={isLoading.campaignStatisticsLoading}
                /> */}
                {/* <TrackingDrawer /> */}
                <h2 className="title display-2">Blockchain-Ads Pixel</h2>
                <Button
                    id="tracking-tutorial-2"
                    name={"Setup Tracking"}
                    variant="contained"
                    // startIcon={question}
                    color="success"
                    startIcon={plus}
                    onClick={() => {
                        setDefaultTab(0);
                        setIsEditingMode(false)
                        setState({})
                        navigate("/tracking/create-pixel")
                    }}
                />
                {/* <Link to="/tracking/create-pixel">Test</Link> */}
            </div>
            <br />

            {conversionOptions.advertiserPixelStats.isLoading ? (
                <TableSkeleton message="Loading..." icon={textPreloader} />
            ) : (
                <>
                    <div className="scroll shadow" id="tour-step-3">
                        <table className="conversion-table">
                            <thead>
                                <tr>
                                    <th className="text-start">Conversion Name</th>
                                    <th className="align-start">Status</th>
                                    <th>Events Tracked</th>
                                    <th>Date created</th>
                                    <th>Last Recorded</th>
                                    <th style={{ width: 0 }}>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {!conversionOptions.advertiserPixelStats.value.length ? (
                                    <tr>
                                        <td colSpan="10">
                                            <div className="no-data-conatiner">
                                            <div className="no-data">No Data To Show!</div>
                                            <br />
                                            <CodeType className="tracking-cover" />
                                            <br />
                                            <h4>Install Blockchain Pixel to track conversions
                                            from your advertising Campaigns</h4>
                                            <br />
                                            <div>
                                                {/* <TrackingDrawer name="Install Pixel"/> */}
                                            </div>
                                            </div>
                                       
                                        </td>
                                    </tr>
                                ) : (
                                    conversionOptions.advertiserPixelStats.value.map((item, index) => {
                                        { console.log("pop123", item); }
                                        return <ConversionTableRow key={index} {...{ item, index }}/>;
                                    })
                                )}
                            </tbody>
                        </table>
                    </div>
                    <br />
                    <br />
                    <br />
                </>
            )}
            {conversionOptions.advertiserPixelStats.value.length ? <CodeType className="tracking-cover" /> : null }
            
        </motion.main>
    );
}

const TrackingJoyride = () => {
    const defaultSteps = [
        {
            content: (
                <h2 className="joyride-subtitle">
                    Welcome to the tracking guide. Follow these steps to track conversions.
                </h2>
            ),
            locale: { skip: <strong>Skip</strong> },
            placement: "center",
            target: "body",
            title: "Welcome to Tracking Guide",
        },

        {
            content: (
                <h2 className="joyride-subtitle">Click here to view the Blockchain-Ads tracking script.</h2>
            ),
            locale: { skip: <strong>Skip</strong> },
            placement: "auto",
            target: "#tracking-tutorial-2",
            title: "Viewing Tracking Script",
        },
    ];

    const [{ run, steps }, setSteps] = useState({
        run: false,
        steps: defaultSteps,
    });


    const handleJoyrideCallback = (data) => {
        const { status, type, index } = data;

        if (status === "ready" || status === "finished") {
            let guideTutorials = JSON.parse(localStorage.getItem("guideTutorials"));
            if (!guideTutorials) return;
            guideTutorials = guideTutorials.filter((item) => item !== "trackingTutorial");
            localStorage.setItem("guideTutorials", JSON.stringify(guideTutorials));
        }
    };

    useEffect(() => {
        const guideTutorials = JSON.parse(localStorage.getItem("guideTutorials"));
        if (!guideTutorials || !guideTutorials.includes("trackingTutorial")) return;
        setTimeout(() => {
            setSteps((prev) => ({ ...prev, run: true }));
        }, 500);
    }, []);


    return (
        <Joyride
            continuous
            callback={handleJoyrideCallback}
            run={run}
            steps={steps}
            // hideBackButton
            // scrollToFirstStep
            showSkipButton
            showProgress
            disableBeacon
            // disableScrolling={true}
            // disableScrollParentFix={true}
            scrollOffset={200}
            locale={{
                last: "Complete",
            }}
            styles={{
                options: {
                    zIndex: 10000,
                },
            }}
        />
    );
};

const ConversionTableRow = ({ item, index }) => {    
    const {
        requestedData,
        successAlert,
        errorAlert,
        getAdvertiserPixelStatsRequest,
        conversionOptions,
        setConversionOptions,
        requestedData: {
            userProfileData: { currentOrganization },
            campaignData: { currentOrganizationCampaigns },
        },
        isLoading: { campaignDataIsLoading },
        trackingState,
        DeleteConversionPixelRequest,
    } = useGlobalContext();

    const { setDisplayInstallPixel, setDefaultTab, setIsEditingMode, setEditingTagId, setState } =
        trackingState;

    const { formatDateToYYYYMMDD } = useFormatter();
    const { DeleteConversionPixel } = useFetch();

    const [isStatLoading, setIsStatLoading] = useState(false);
    const [isVerified, setIsVerified] = useState(item.conversionCount > 0);

    const {info} = localData.svgs

    const isGray = index % 2 === 0;

    const navigate = useNavigate()

    const formatDate = (timestamp) => {
        const date = new Date(timestamp);
        
        const options = { 
          month: 'long', 
          day: 'numeric', 
          year: 'numeric', 
          hour: '2-digit', 
          minute: '2-digit',
          hour12: false 
        };
        
        return date.toLocaleDateString('en-US', options);
      };
    
    return (
      <tr style={{ backgroundColor: isGray ? '#d3d3d382' : 'white' }}>
        <td className="text-start">{item.tagName || "unknown"}</td>
        <td>
          {!isVerified ? (
            <span
              className="unverified"
              data-tooltip-id="tooltip-unverified"
              data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                <p>An Insight Tag isn't installed on your website.<br /> If you've already tried to install it, make sure it was done correctly.</p>
              )}
            >
              Unverified
            </span>
          ) : (
            <span className="verified">Verified</span>
          )}
          <Tooltip
            id="tooltip-unverified"
            className="custom-tooltip sidebar-tooltip"
          />
        </td>
        <td className="text-center">{item.conversionCount || 0}</td>
        <td className="text-center">
          {formatDateToYYYYMMDD(new Date(item.created_at._seconds * 1000)) || 0}
        </td>
        <td className="text-center">{item.lastTriggeredTs == null ? null : formatDate(item.lastTriggeredTs)}</td>
        <td style={{ width: 0 }} className="text-center table-td-actions">
          <div className="btn-group">
            <Button
              variant="circle"
              icon={penV2}
              color="dark"
              size="sm"
              onClick={() => {
                // setDisplayInstallPixel("block");
                setDefaultTab(1);
                setIsEditingMode(true);
                setEditingTagId(item.tagId);
                setState({ tagName: item.tagName, url: "", contains: "" });
                navigate("/tracking/create-pixel")
              }}
            />
            <Button
              variant="circle"
              icon={trashV2}
              color="danger"
              size="sm"
              onClick={() =>
                DeleteConversionPixelRequest({
                  tagId: item.tagId,
                  setIsLoading: setIsStatLoading,
                })
              }
              disabled={isStatLoading}
            />
          </div>
        </td>
      </tr>
    );
};
