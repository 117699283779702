import React, { useState, useEffect } from "react";
import { Button, Checkbox } from "../../../components";
import { useGlobalContext } from "../../../context";
import localData from "../../../localData";

export default function CampaignBuildOptionModal({ setIsOpen, setShowCampaignObjectiveModal }) {
    const {
        errorAlert,
        requestedData,
        formatNumber,
        isDarkModeEnabled,
        setShowCampaignWizardDrawer,
        isAssistedCampaignEnabled,
        setIsAssistedCampaignEnabled,
        requestedData: {
            userProfileData: {
                currentOrganization: { balance, PredictedBalance },
            },
        },
    } = useGlobalContext();

    const { preloaderBig, pay, plants } = localData.images;
    const { close, wallet, budget } = localData.svgs;

    const closeModal = () => {
        setIsOpen(false);
    };

    const [isAISelected, setIsAISelected] = useState(isAssistedCampaignEnabled);

    useEffect(() => {
        const isEnabled = JSON.parse(localStorage.getItem("isAssistedCampaignEnabled"));

        if (isEnabled === true) {
            setIsAssistedCampaignEnabled(true);
            setIsAISelected(true)
        } else if (isEnabled === false) {
            setIsAssistedCampaignEnabled(false);
            setIsAISelected(false)
        }
    }, []);

    const handleSubmit = ()=>{
        setIsAssistedCampaignEnabled(isAISelected);
        localStorage.setItem("isAssistedCampaignEnabled", isAISelected);
    }

    return (
        <>
            <div className="modal-header">
                <h2 className="modal-title">How do you want to build your campaign?</h2>
                <p className="modal-description">We’ll make recommendations based on your selection</p>
                <Button
                    className="btn-close"
                    variant="circle"
                    color="dark"
                    size="sm"
                    icon={close}
                    onClick={closeModal}
                />
            </div>
            <div className="modal-body">
                <div
                    className="build-option-card card"
                    style={{ marginBottom: "30px" }}
                    // onClick={() => setBuildOption("AI")}
                    onClick={() => setIsAISelected(true)}
                >
                    <h4 className="card-title">
                        AI Assisted <div className="badge">Beta</div>
                    </h4>
                    <div className="card-description">
                        Create campaigns that maximize performance using algorithmic recommendations and best
                        practices.{" "}
                    </div>
                    <Checkbox
                        callback={() => {}}
                        checked={isAISelected}
                        color="success"
                        size="sm"
                    />
                </div>
                <div
                    className="build-option-card card"
                    // onClick={() => setBuildOption("classic")}
                    onClick={() => setIsAISelected(false)}
                >
                    <h4 className="card-title">Classic</h4>
                    <div className="card-description">
                        Build a campaign yourself using the features available in Campaign Manager.
                    </div>
                    <Checkbox
                        callback={() => {}}
                        checked={!isAISelected}
                        color="success"
                        size="sm"
                    />
                </div>
            </div>
            <div className="modal-footer">
                <div className="btn-group">
                    <Button
                        name="Back"
                        color="light"
                        variant="contained"
                        onClick={() => {
                            setShowCampaignObjectiveModal("block");
                            closeModal();
                        }}
                    />
                    <Button
                        name="Next"
                        color="success"
                        variant="contained"
                        onClick={() => {
                            handleSubmit()
                            setShowCampaignWizardDrawer("block");
                            closeModal();
                        }}
                    />
                </div>
            </div>
        </>
    );
}
