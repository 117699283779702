import React, { useState, useEffect, useRef } from "react";
import {
    Campaigns,
    Campaign,
    Analytics,
    Profile,
    Chat,
    Support,
    Referral,
    PR,
    ShoppingCart,
    Wishlist,
    Tracking,

    // public routes
    SignIn,
    SignUp,
    ForgotPassword,
    Error,
    Qualification,

    // public and protected routes
    PrivacyPolicy,
    Invitation,
    Insights,
} from "./pages/index.js";
import { Header, Sidebar, Button, Loading, Navbar } from "./components/index.js";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import { useGlobalContext } from "./context.js";
import localData from "./localData.js";
import { auth } from "./config/firebase.js";
import { Tooltip } from "react-tooltip";
import Joyride, { STATUS } from "react-joyride";
import { ToastContainer, toast } from "react-toastify";
import CreatePixel from "./pages/tracking/CreatePixel/CreatePixel.jsx";

const links = ["/campaigns", "/analytics", "/profile", "/chat", "/support", "/quests", "/boost", "/referral"];

// const SESSION_TIMEOUT = 24 * 60 * 60 * 1000; // 1 day
// const CHECK_INTERVAL = 60 * 60 * 1000; // 1 hour

const SESSION_TIMEOUT = 12 * 60 * 60 * 1000; // 12 hours
const CHECK_INTERVAL = 1 * 60 * 60 * 1000; // 1 hour

export default function App() {
    const location = useLocation();    
    const navigate = useNavigate();

    const {
        initRequests,
        navbarRef,
        marketplaceScrollItems,
        marketplaceTheadRef,
        successAlert,
        warningAlert,
        campaignState,
        setCampaignState,
        isDarkModeEnabled,
        requestedData,
        isLoading,
        isGoogleSignUpLoading,
        isGoogleSignInLoading,
        handleSignOut,
        setAuthUser,
        errorAlert,
        errorAlertExpanded,
        warningAlertExpanded,
        successAlertExpanded,
        assistedCampaignData,
        setAssistedCampaignData,
        requestedData: {
            userProfileData: {
                currentOrganization,
                currentOrganization: { additionalData },
            },
        },
    } = useGlobalContext();
    const { arrowRight } = localData.svgs;

    const [showModal, setShowModal] = useState(false);
    
    const [isAuthUserLoading, setIsAuthUserLoading] = useState(true);
    const [isLoggedIn, setIsLoggedIn] = useState(auth?.currentUser?.email);
    const [isAdditionalFormCompleted, setIsAdditionalFormCompleted] = useState(true);
    
    useEffect(() => {
      if (
          currentOrganization.account_status === "Inactive" && isAdditionalFormCompleted === true
          
      ) {
          setShowModal(true);  
          navigate("/insights");
      }
  }, [currentOrganization.account_status, isAdditionalFormCompleted]);

  useEffect(() => {
    let timer;
    if (!showModal && currentOrganization.account_status === "Inactive"&& isAdditionalFormCompleted === true) {
        timer = setTimeout(() => {
            setShowModal(true);
        }, 90000); 
    }

    return () => clearTimeout(timer);
}, [showModal, currentOrganization.account_status]);

    const mainContentRef = useRef(null);
    useEffect(() => {
        setTimeout(() => {
            mainContentRef?.current?.scrollTo({
                top: 0,
                // top: 500,
                // behavior: "smooth",
                behavior: "auto",
            });
        }, 500);
    }, [location.pathname, requestedData.userProfileData]);

    useEffect(() => {
        setCampaignState({
            ...campaignState,
            uuid: auth?.currentUser?.uid,
        });
        setAuthUser(auth);
        if (isGoogleSignInLoading) return;
        if (isGoogleSignUpLoading) return;
        if (!auth?.currentUser) return;
        initRequests();
    }, [auth?.currentUser]);

    useEffect(() => {
        if (isAdditionalFormCompleted === false) navigate("/qualification");
    }, [isAdditionalFormCompleted]);

    useEffect(() => {
        const checkSessionTimeout = () => {
            const lastActivityTime = parseInt(localStorage.getItem("lastActivityTime")) || 0;
            const inactivityDuration = Date.now() - lastActivityTime;

            if (inactivityDuration > SESSION_TIMEOUT) {
                successAlert("Session timeout");
                handleSignOut();
            }
        };
        const checkAdditionalForm = () => {
            const isCompleted = JSON.parse(localStorage.getItem("isAdditionalFormCompleted"));
            if (isCompleted === null || isCompleted === true) {
                setIsAdditionalFormCompleted(true);
            } else if (isCompleted === false) {
                setIsAdditionalFormCompleted(false);
            }
        };

        checkAdditionalForm();

        const updateActivityTime = () => {
            localStorage.setItem("lastActivityTime", Date.now());
        };

        const unsubscribe = auth.onAuthStateChanged((user) => {
            setIsLoggedIn(!!user);
            setIsAuthUserLoading(false);

            if (!user) {
                if (
                    location.pathname !== "/sign-in" &&
                    location.pathname !== "/sign-up" &&
                    location.pathname !== "/forgot-password" &&
                    location.pathname !== "/privacy-policy" &&
                    location.pathname !== "/invitation" &&
                    location.pathname !== "/insights" &&
                    location.pathname !== "/support"
                ) {
                    navigate("/sign-in");
                }
            } else if (location.pathname === "/sign-in" || location.pathname === "/sign-up") {
                navigate("/");
            }
            if (user) {
                // Update activity time when the user interacts with the app
                document.addEventListener("mousemove", updateActivityTime);
                document.addEventListener("keydown", updateActivityTime);

                // Check for inactivity periodically
                const intervalId = setInterval(checkSessionTimeout, CHECK_INTERVAL);

                return () => {
                    clearInterval(intervalId);
                    document.removeEventListener("mousemove", updateActivityTime);
                    document.removeEventListener("keydown", updateActivityTime);
                };
            }
        });

        return () => unsubscribe();
    }, [auth, location, navigate]);

    useEffect(() => {
        if (!mainContentRef.current || !navbarRef.current) return;
        const shrink = () => {
            navbarRef.current.classList.toggle("shrink", mainContentRef.current.scrollTop > 0);
        };

        const mainContentElement = mainContentRef.current;

        mainContentElement.addEventListener("scroll", shrink);

        return () => {
            mainContentElement.removeEventListener("scroll", shrink);
        };
    }, [mainContentRef.current, navbarRef.current]);

    // useEffect(() => {
    //     if (!mainContentRef.current || !marketplaceTheadRef.current || !marketplaceScrollItems.current)
    //         return;
    //     const init = () => {
    //         // marketplaceTheadRef.current.classList.toggle(
    //         //     "fixed",
    //         //     mainContentRef.current.scrollTop > 650
    //         // );
    //         if (marketplaceTheadRef.current.offsetTop < marketplaceScrollItems.current.offsetTop) {
    //             const top = marketplaceScrollItems.current.offsetTop - 20;
    //             marketplaceTheadRef.current.style.transform = `translateY(${top}px)`;
    //         } else {
    //             marketplaceTheadRef.current.style.transform = `none`;

    //         }
    //     };

    //     const mainContentElement = mainContentRef.current;

    //     mainContentElement.addEventListener("scroll", init);

    //     return () => {
    //         mainContentElement.removeEventListener("scroll", init);
    //     };
    // }, [mainContentRef.current, marketplaceTheadRef.current, marketplaceScrollItems.current]);

    useEffect(() => {
        const marketplace = ["/chat", "/additional-products/marketplace"];
        if (marketplace.includes(location.pathname)) {
            document.body.classList.add("marketplace-mode");
        } else {
            document.body.classList.remove("marketplace-mode");
        }
    }, [location]);

    useEffect(() => {
        if (auth.currentUser && additionalData._category) {
            if (!auth.currentUser.emailVerified) {
                // warningAlertExpanded("Verify your account email - (check your inbox to verify)");
                warningAlertExpanded(
                    "Verify your account! Check your inbox and click the link in the email to complete verification."
                );
            }
        }
    }, [auth.currentUser, additionalData]);

    useEffect(() => {
        if (additionalData._category) {
            let tempAudiences = [...assistedCampaignData];
            if(additionalData._category == 'Web3 Casino') additionalData._category = "Betting & Gambling"
            if(additionalData._category == 'blockchainGaming') additionalData._category = "Blockchain Gaming"
            if(additionalData._category == "infrastructure") additionalData._category = "Infrastructure"
            let matchingVertical = tempAudiences.find(item=> item.category == additionalData._category)
            
            tempAudiences = tempAudiences.map((item) => {
                // if (item.propertyName == additionalData._category) matchingVertical = item;

                if (item.propertyName !== "custom" || !matchingVertical) {
                    return {
                        ...item,
                        isSelected: false,
                    };
                } else {

                    return {
                        ...item,
                        isSelected: true,
                        targetURL: additionalData.websiteURL || "",
                        smartContractAddress: additionalData._smartContractAddress || "",
                        chainTargeting: additionalData._chainTargeting,
                        walletTargeting: additionalData._walletTargeting,

                        campaignName: matchingVertical.campaignName,
                        campaignType: matchingVertical.campaignType,
                        web3Audience: matchingVertical.web3Audience,
                        category: [matchingVertical.category],
                        operativeSystem: matchingVertical.operativeSystem,
                        browser: matchingVertical.browser,
                        geo: matchingVertical.geo,
                        deviceType: matchingVertical.deviceType,
                    };
                }
            });
            setAssistedCampaignData(tempAudiences);
        } else {
            let tempAudiences = [...assistedCampaignData];
            tempAudiences = tempAudiences.map((item) => ({
                ...item,
                isSelected: false,
            }));
            setAssistedCampaignData(tempAudiences);
        }
    }, [additionalData]);

    const [pageTitle, setPageTitle] = useState("");
  
    useEffect(() => {
        const pathToTitleMap = {
            "/": "Campaigns",
            "/campaigns": "Campaigns",
            "/campaigns/:id": "Campaign Details",
            "/analytics": "Analytics",
            "/profile": "",
            "/support": "Support",
            "/referral": "Referral",
            "/tracking": "Tracking",
            "/additional-products/ask-ai": "Ask AI",
            "/additional-products/pr": "PR",
            "/additional-products/pr/shopping-cart": "Shopping Cart",
            "/additional-products/pr/wishlist": "Wishlist",
            "/insights": "Live Stats",
            "*": "Page Not Found",
          };
          
          const pathname = location.pathname;
          
          let pageTitle = "Page Not Found";
          
          switch (pathname) {
            case "/":
              pageTitle = pathToTitleMap["/"];
              break;
            case "/campaigns":
              pageTitle = pathToTitleMap["/campaigns"];
              break;
            case pathname.match(/\/campaigns\/\d+/)?.input:
              pageTitle = pathToTitleMap["/campaigns/:id"];
              break;
            case "/analytics":
              pageTitle = pathToTitleMap["/analytics"];
              break;
            case "/profile":
              pageTitle = pathToTitleMap["/profile"];
              break;
            case "/support":
              pageTitle = pathToTitleMap["/support"];
              break;
            case "/referral":
              pageTitle = pathToTitleMap["/referral"];
              break;
            case "/tracking":
              pageTitle = pathToTitleMap["/tracking"];
              break;
            case "/additional-products/ask-ai":
              pageTitle = pathToTitleMap["/additional-products/ask-ai"];
              break;
            case "/additional-products/pr":
              pageTitle = pathToTitleMap["/additional-products/pr"];
              break;
            case "/additional-products/pr/shopping-cart":
              pageTitle = pathToTitleMap["/additional-products/pr/shopping-cart"];
              break;
            case "/additional-products/pr/wishlist":
              pageTitle = pathToTitleMap["/additional-products/pr/wishlist"];
              break;
            case "/insights":
              pageTitle = pathToTitleMap["/insights"];
              break;
            default:
              pageTitle = pathToTitleMap["*"];
          }
          setPageTitle(pageTitle);
        }, [location])
          

    if (isAuthUserLoading) return <Loading className="extended" />;

    const openModal = () => setShowModal(true);
    const closeModal = () => setShowModal(false);

    return (
      <>
        {/* {isLoggedIn ? ( */}
        {isLoggedIn && isAdditionalFormCompleted ? (
          <>
            {!location.pathname.includes("/invitation") &&
            !location.pathname.includes("/privacy-policy") ? (
              <>
                {currentOrganization.account_status == "Inactive" ? (
                  <Sidebar isPublic={true} />
                ) : (
                  <Sidebar />
                )}
                <div
                  className={`main-content ${
                    isDarkModeEnabled ? "dark-mode" : ""
                  }`}
                  ref={mainContentRef}
                >
                  <Navbar title={pageTitle} accountStatus={currentOrganization.account_status}/>
                  {/* <ToastContainer autoClose={3000} /> */}
                  <AnimatePresence mode="wait">
                    <Routes location={location} key={location.pathname}>
                      <Route path="/" element={<Campaigns />} />
                      <Route path="/campaigns" element={<Campaigns />} />
                      <Route path="/campaigns/:id" element={<Campaign />} />
                      <Route path="/analytics" element={<Analytics />} />
                      <Route path="/profile" element={<Profile />} />
                      <Route path="/support" element={<Support />} />
                      <Route path="/referral" element={<Referral />} />
                      <Route path="/tracking" element={<Tracking />} />
                      <Route path="/tracking/create-pixel" element={<CreatePixel />}/>
                      <Route
                        path="additional-products/ask-ai"
                        element={<Chat />}
                      />
                      <Route path="/additional-products/pr" element={<PR />} />
                      <Route
                        path="/additional-products/pr/shopping-cart"
                        element={<ShoppingCart />}
                      />
                      <Route
                        path="/additional-products/pr/wishlist"
                        element={<Wishlist />}
                      />

                      <Route
                        path="/insights"
                        element={
                          <Insights
                            isPublic={false}
                            mainContentRef={mainContentRef}
                          />
                        }
                      />
                      <Route path="*" element={<Error />} />
                    </Routes>
                  </AnimatePresence>
                </div>
              </>
            ) : (
              <Routes location={location} key={location.pathname}>
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/invitation" element={<Invitation />} />
              </Routes>
            )}
          </>
        ) : (
          <>
            {!location.pathname.includes("/insights") &&
            !location.pathname.includes("/support")
           ? (
              <AnimatePresence mode="wait">
                <Routes location={location} key={location.pathname}>
                  <Route path="/sign-in" element={<SignIn />} />
                  <Route path="/sign-up" element={<SignUp />} />
                  <Route path="/forgot-password" element={<ForgotPassword />} />
                  <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                  <Route path="/invitation" element={<Invitation />} />
                  <Route path="/qualification" element={<Qualification />} />
                  <Route path="*" element={<Error />} />
                </Routes>
              </AnimatePresence>
            ) : (
              <>
                <Sidebar isPublic={true} />
                <div className="main-content" ref={mainContentRef}>
                  <Navbar title={pageTitle} isPublic={true} />
                  <AnimatePresence mode="wait">
                    <Routes location={location} key={location.pathname}>
                      <Route
                        path="/insights"
                        element={
                          <Insights
                            isPublic={true}
                            mainContentRef={mainContentRef}
                          />
                        }
                      />
                      <Route
                        path="/support"
                        element={<Support isPublic={true} />}
                      />
                    </Routes>
                  </AnimatePresence>
                </div>
              </>
            )}
          </>
        )}
        <Tooltip id="tooltip-table-name" className="custom-tooltip" />
        <Tooltip id="tooltip-table-settings" className="custom-tooltip" />
        <Tooltip id="tooltip-table-status" className="custom-tooltip" />
        <Tooltip id="tooltip-table-impressions" className="custom-tooltip" />
        <Tooltip id="tooltip-table-clicks" className="custom-tooltip" />
        <Tooltip id="tooltip-table-ctr" className="custom-tooltip" />
        <Tooltip id="tooltip-table-ad-spend" className="custom-tooltip" />
        <Tooltip id="tooltip-table-cpc" className="custom-tooltip" />
        <Tooltip id="tooltip-table-details" className="custom-tooltip" />
        <Modal isOpen={showModal} onClose={closeModal}>
          <div className="modal-header">
            <h1>Unlock Full Access to Your One-Stop Web3 Marketing Tool</h1>
          </div>
          <div className="modal-body">
          <p className="text-style-1"> Complete the qualification process to get full access to Blockchain-Ads</p>
          <p className="text-style-1"> If you haven’t scheduled your qualification meeting yet, book to get started.</p>
          <div className="cta-conatiner">
              <Button
                name="Book Meeting to Qualify"
                variant="contained"
                color="success"
                onClick={() => {
                  window.location.href = "https://calendly.com/blockchain-ads-marko/30min";
                }}
              />
          </div>
          </div>
        
        </Modal>
      </>
    );
}



const Modal = ({ isOpen, onClose, children }) => {
    if (!isOpen) return null;
  
    return (
      <div className="modal-overlay" onClick={onClose}>
        <div className="modal-content-inactive-user" onClick={(e) => e.stopPropagation()}>
        {children}
        </div>
      </div>
    );
};