import React, { useEffect, useState } from "react";
import { Drawer, DepositComponent, Sidebar } from "../index.js";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button, Modal } from "../index.js";
import { useGlobalContext } from "../../context.js";
import localData from "../../localData.js";
import { auth } from "../../config/firebase.js";

export default function Navbar({ title = "", description = "", titlePopup = "", isPublic = false, mainContentRef, accountStatus ="Inactive" }) {
    console.log("mainContentRef", mainContentRef);
    console.log("accountStatus", accountStatus);
    const { avatar, user, hamburgerMenu } = localData.svgs;
    const {
        showSidebar,
        setShowSidebar,
        successAlert,
        showProfileDrawer,
        setShowProfileDrawer,
        showDepositModal,
        setShowDepositModal,
        requestedData,
        requestedData: { userProfileData },
        requestedData: {
            userProfileData: {
                currentOrganization: { balance },
            },
        },
        isLoading: { userProfileIsLoading },
        navbarRef,
        formatNumber,
        isDarkModeEnabled,
        setIsDarkModeEnabled,
        handleSignOut,
    } = useGlobalContext();
    const { arrowDown, bars, barsClose, sun, moon, deposit, usersIcon } = localData.svgs;
    const { textPreloader } = localData.images;

    const [showHamburgerDrawer, setShowHamburgerDrawer] = useState(false);

    const DrawerComponent = ({ callbackFromParent }) => {
        const { logout } = localData.svgs;
        return (
            <>
                <div className="drawer-header">
                    <img src={userProfileData.profile_image} alt="" />
                    <p className="drawer-text">
                        Welcome to Blockchain-Ads
                        <br />{" "}
                        <strong>
                            {userProfileIsLoading ? (
                                <img src={textPreloader} className="text-preloader" />
                            ) : (
                                userProfileData.userName
                            )}
                        </strong>
                    </p>
                </div>

                <div className="drawer-body">
                    <Link to="/profile">
                        <Button name="My Profile" color="dark" onClick={callbackFromParent} />
                    </Link>
                    <br />
                    <Link to="/profile?tab=3">
                        <Button name="Manage Access" color="dark" onClick={callbackFromParent} />
                    </Link>

                    <br />

                    <Button
                        name="deposit funds"
                        color="dark"
                        onClick={() => {
                            setShowDepositModal("block");
                        }}
                    />
                    <br />

                    <Link to="/referral">
                        <Button name="Referral Program" color="dark" onClick={callbackFromParent} />
                    </Link>
                    <br />
                    <br />

                    <Button
                        name="log out"
                        color="danger"
                        variant="outlined"
                        startIcon={logout}
                        onClick={() => {
                            callbackFromParent();
                            setTimeout(() => {
                                handleSignOut();
                            }, 1000);
                        }}
                    />
                </div>

                <div className="drawer-footer">
                    <div className="wrapper">
                        <Button
                            icon={sun}
                            variant="text"
                            color="success"
                            className={`mode ${isDarkModeEnabled ? "" : "active"}`}
                            onClick={() => {
                                setIsDarkModeEnabled(false);
                                document.body.classList.remove("dark-mode");
                                localStorage.setItem("isDarkModeEnabled", false);
                            }}
                        />
                        <Button
                            icon={moon}
                            variant="contained"
                            color="dark"
                            className={`mode ${isDarkModeEnabled ? "active" : ""}`}
                            onClick={() => {
                                setIsDarkModeEnabled(true);
                                document.body.classList.add("dark-mode");
                                localStorage.setItem("isDarkModeEnabled", true);
                            }}
                        />
                    </div>
                </div>
            </>
        );
    };

    const HamburgerDrawerComponent = ({ showHamburgerDrawer, setShowHamburgerDrawer }) => {
        return (
            <>
                <Sidebar showHamburgerDrawer={showHamburgerDrawer} setShowHamburgerDrawer={setShowHamburgerDrawer} />
            </>
        );
    };

    return (
        <>
            <nav className={`navbar navbar-expand-lg ${isPublic ? "navbar-public" : ""}`} ref={navbarRef}>
                <div className="wrapper">
                    <div className="deposit-container">
                        {accountStatus !== "Inactive" ? <React.Fragment>
                            <div className="budget dark-mode-style">
                            <p className="budget-title">BALANCE</p>
                            <div className="budget-balance">
                                ${balance > 0 ? formatNumber(balance?.toFixed(0) || "0") : 0}
                            </div>
                        </div>
                        <div id="login-tutorial-2">
                            <Modal
                                Child={DepositComponent}
                                className="deposit-modal modal-dialog-centered"
                                buttonClassName={`rounded-pill deposit-btn ${window.innerWidth < 576 ? "btn-sm" : ""}`}
                                display={showDepositModal}
                                setDisplay={setShowDepositModal}
                                Toggler={() => (
                                    <Button
                                        tooltipContent={
                                            <div>
                                                Top up your account balance to use <br /> Blockchain-Ads.
                                            </div>
                                        }
                                        name="Deposit"
                                        variant="contained"
                                        color="primary"
                                        className={`rounded-pill deposit-btn ${window.innerWidth < 576 ? "btn-sm" : ""}`}
                                        id="login-tutorial-2"
                                        startIcon={deposit}
                                        onClick={() => setShowDepositModal("block")}
                                    />
                                )}
                            ></Modal>
                        </div>
                        </React.Fragment> : <React.Fragment>
                            <Button
                                tooltipContent={
                                    <div>
                                        Acctivate your Account
                                    </div>
                                }
                                name="Activate Account"
                                variant="contained"
                                color="primary"
                                className={`rounded-pill deposit-btn ${window.innerWidth < 576 ? "btn-sm" : ""}`}
                                id="login-tutorial-2"
                                onClick={() => window.location.href = "https://calendly.com/blockchain-ads-marko/30min"}
                            />
                            </React.Fragment>}

                        {/* <div>
                            <Link to="/profile?tab=invite">
                                <Button
                                    tooltipContent={
                                        <div>
                                            Add users to your organization
                                        </div>
                                    }
                                    name="Add users"
                                    variant="outlined"
                                    color="primary"
                                    className={`rounded-pill deposit-btn ${window.innerWidth < 576 ? "btn-sm" : ""}`}
                                    id="login-tutorial-3"
                                    startIcon={usersIcon}
                                // onClick={() => window.loc}
                                />
                            </Link>
                        </div> */}
                    </div>
                    {!isPublic ? (
                        <Drawer
                            Component={DrawerComponent}
                            className="drawer-right"
                            display={showProfileDrawer}
                            setDisplay={setShowProfileDrawer}
                            disabled={userProfileIsLoading}
                            toggler={
                                <Button color="dark" variant="text">
                                    <div className="profile  dark-mode-style">
                                        <div className="profile-image">
                                            <img src={userProfileData.profile_image} alt="" />
                                        </div>
                                        {/* <div className="profile-name">
                                            {userProfileIsLoading ? (
                                                <img src={textPreloader} className="text-preloader" />
                                            ) : (
                                                userProfileData.userName
                                            )}
                                        </div> */}
                                        <div className="profile-icon">{arrowDown}</div>
                                    </div>
                                </Button>
                            }
                        />
                    ) : (
                        <Link to="/sign-up" className="sing-up-btn">
                            <Button name="sign up" variant="outlined" size="sm" />
                        </Link>
                    )}

                    <div className="hamburger-menu">
                        <Drawer
                            Component={() => <HamburgerDrawerComponent showHamburgerDrawer={showHamburgerDrawer} setShowHamburgerDrawer={setShowHamburgerDrawer} />}
                            className="drawer-right drawer-sidebar"
                            display={showHamburgerDrawer}
                            setDisplay={setShowHamburgerDrawer}
                            toggler={
                                <div onClick={() => setShowHamburgerDrawer(!showHamburgerDrawer)}>
                                    {hamburgerMenu}
                                </div>
                            }
                        />
                    </div>
                </div>
            </nav>
        </>
    );
}
