import React, { useState, useEffect } from "react";
import useUtil from "../../../../../hooks/useUtil";
import { useGlobalContext } from "../../../../../context";
import { CopyTextButton, Button, Modal, Tabs } from "../../../../../components";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { coy } from "react-syntax-highlighter/dist/esm/styles/prism";
import localData from "../../../../../localData";
import { Tooltip } from "react-tooltip";
import ReactDOMServer from "react-dom/server";
import TrackingHelp from "../../../tracking-help/TrackingHelp";
import Joyride, { STATUS } from "react-joyride";

const {
    edit,
    question,
    password,
    support,
    userShield,
    ellipsisVeritcal,
    arrowRight,
    penV2,
    textPreloader,
    BloomingImage,
    info,
} = localData.svgs;
const { avatar, gtm, nextjs, html, nuxt, wordpress, pixel } = localData.images;

export default function InstallPixelTab({ setActiveTab, activeTab }) {
    const {
        CreatePixelTagRequest,
        CreateConversionPixelRequest,
        conversionOptions,
        setConversionOptions,
        requestedData,
        requestedData: {
            userProfileData: { currentOrganization },
        },
    } = useGlobalContext();

    const [script, setScript] = useState("");

    useEffect(() => {
        if (requestedData?.userProfileData?.currentOrganization?.id) {
            CreatePixelTagRequest();
        }
    }, [requestedData]);

    const [showInstallPixelModal, setShowInstallPixelModal] = useState("none");

    const [instalPixelActiveTab, setInstalPixelActiveTab] = useState("")

    console.log("instalPixelActiveTab" ,instalPixelActiveTab);

    const [pixelTab, setPixelTab] = useState(instalPixelActiveTab)

    return (
        <div className="install-pixel-tab">
            <InstallPixelJoyRide activeTab={activeTab} />
            {/* <h4 className="install-pixel-tab-title " id="install-pixel-tutorial-1">
                Install Pixel
            </h4> */}
            <br />

            <Tabs
                contentId="install-pixel-tutorial-2"
                // hideTabs={true}
                tabsProps={{}}
                tabs={installPixelCodeTabs}
                title="fsdfsd"
                callback={setInstalPixelActiveTab}
                activeTabInstalPixel={pixelTab}
                // indicatorIcon={question}
                // className="install-pixel-code-tabs"
            />
            <br />
            <TrackingHelp />

            <br />
          
            <div className="install-pixel-tab-footer">
                <div className="btn-group">
                    <Modal
                        Child={InstallPixelModal}
                        className="install-pixel-modal tracking-modal modal-dialog-centered"
                        display={showInstallPixelModal}
                        setDisplay={setShowInstallPixelModal}
                        restProps={{ setActiveTab }}
                        Toggler={() => (
                            <Button
                                id="install-pixel-tutorial-3"
                                className="next-step-btn"
                                name="Next"
                                endIcon={arrowRight}
                                size="sm"
                                variant="contained"
                                color="pale-dark"
                                onClick={() => setShowInstallPixelModal("block")}
                            />
                        )}
                    ></Modal>
                </div>
            </div>
        </div>
    );
}

const InstallPixelJoyRide = ({ activeTab }) => {
    const defaultSteps = [
        {
            content: (
                <h2 className="joyride-subtitle">
                    Follow on-screen instructions to place the script on your website.
                </h2>
            ),
            locale: { skip: <strong>Skip</strong> },
            placement: "center",
            target: "body",
            title: "Placing Tracking Script",
        },
        {
            content: (
                <h2 className="joyride-subtitle">
                    Copy paste this script in the {"<head>"} tag of your website or in a custom html tag on
                    Tag Manager.
                </h2>
            ),
            locale: { skip: <strong>Skip</strong> },
            placement: "auto",
            target: "#install-pixel-tutorial-2",
            title: "Copying Tracking Script",
        },
        {
            content: <h2 className="joyride-subtitle">Click here to continue.</h2>,
            locale: { skip: <strong>Skip</strong> },
            placement: "auto",
            target: "#install-pixel-tutorial-3",
            title: "Continuing Setup",
        },
    ];

    const [{ run, steps }, setSteps] = useState({
        run: false,
        steps: defaultSteps,
    });

    const handleJoyrideCallback = (data) => {
        const { status, type, index } = data;

        if (status === "ready" || status === "finished") {
            let guideTutorials = JSON.parse(localStorage.getItem("guideTutorials"));
            if (!guideTutorials) return;
            guideTutorials = guideTutorials.filter((item) => item !== "installPixelTutorial");
            localStorage.setItem("guideTutorials", JSON.stringify(guideTutorials));
        }
    };

    useEffect(() => {
        const guideTutorials = JSON.parse(localStorage.getItem("guideTutorials"));
        if (!guideTutorials || !guideTutorials.includes("installPixelTutorial")) return;
        if (activeTab == 0) {
            setTimeout(() => {
                setSteps((prev) => ({ ...prev, run: true }));
            }, 500);
        }
    }, [activeTab]);

    return (
        <Joyride
            continuous
            callback={handleJoyrideCallback}
            run={run}
            steps={steps}
            // hideBackButton
            // scrollToFirstStep
            showSkipButton
            showProgress
            disableBeacon
            // disableScrolling={true}
            // disableScrollParentFix={true}
            scrollOffset={200}
            locale={{
                last: "Complete",
            }}
            styles={{
                options: {
                    zIndex: 10000,
                },
            }}
        />
    );
};

const InstallPixelModal = ({ setIsOpen, setActiveTab }) => {
    const { requestedData } = useGlobalContext();
    const { close } = localData.svgs;
    const closeModal = () => {
        setIsOpen(false);
    };

    return (
        <>
            <div
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                }}
            >
                <InstallPixelModalJoyRide />
            </div>

            <div className="modal-header">
                <h2 className="modal-title">Confirmation</h2>

                <Button
                    className="btn-close"
                    variant="circle"
                    color="dark"
                    size="sm"
                    icon={close}
                    onClick={closeModal}
                />
            </div>
            <div className="modal-body">
                {/* need to confirm
                <br />
                <br /> */}
                <TrackingHelp />
            </div>
            <div className="modal-footer">
                <div className="btn-group">
                    <Button
                        className="cancel-btn"
                        name="Cancel"
                        size="sm"
                        variant="contained"
                        color="light"
                        onClick={closeModal}
                    />
                    <Button
                        id="install-pixel-modal-tutorial-2"
                        className="next-step-btn"
                        name="Continue"
                        size="sm"
                        variant="contained"
                        color="pale-dark"
                        onClick={(e) => {
                            closeModal();
                            setTimeout(() => {
                                setActiveTab(1);
                            }, 500);
                        }}
                    />
                </div>
            </div>
        </>
    );
};

const InstallPixelModalJoyRide = () => {
    const defaultSteps = [
        {
            content: <h2 className="joyride-subtitle">Review the instructions before proceeding further.</h2>,
            locale: { skip: <strong>Skip</strong> },
            placement: "center",
            target: "body",
            title: "Integration",
        },

        {
            content: (
                <h2 className="joyride-subtitle">Click here to confirm understanding of the instructions.</h2>
            ),
            locale: { skip: <strong>Skip</strong> },
            placement: "auto",
            target: "#install-pixel-modal-tutorial-2",
            title: "Confirm Understanding",
        },
    ];
    const [{ run, steps }, setSteps] = useState({
        run: false,
        steps: defaultSteps,
    });

    useEffect(() => {
        const guideTutorials = JSON.parse(localStorage.getItem("guideTutorials"));
        if (!guideTutorials || !guideTutorials.includes("installPixelModalTutorial")) return;
        setTimeout(() => {
            setSteps((prev) => ({ ...prev, run: true }));
        }, 500);
    }, []);

    const handleJoyrideCallback = (data) => {
        const { status, type, index } = data;

        if (status === "ready" || status === "finished") {
            let guideTutorials = JSON.parse(localStorage.getItem("guideTutorials"));
            if (!guideTutorials) return;
            guideTutorials = guideTutorials.filter((item) => item !== "installPixelModalTutorial");
            localStorage.setItem("guideTutorials", JSON.stringify(guideTutorials));
        }
    };

    return (
        <Joyride
            continuous
            callback={handleJoyrideCallback}
            run={run}
            steps={steps}
            // hideBackButton
            // scrollToFirstStep
            showSkipButton
            showProgress
            disableBeacon
            // disableScrolling={true}
            // disableScrollParentFix={true}
            scrollOffset={200}
            locale={{
                last: "Complete",
            }}
            styles={{
                options: {
                    zIndex: 10000,
                },
            }}
        />
    );
};

const GoogleTagManagerContent = () => {
    const { conversionOptions } = useGlobalContext();
    const gmtLink = `<script>
       var script = document.createElement('script');
       script.defer = true;
       script.src = 'https://cdn.cookie3.co/scripts/analytics/0.11.4/cookie3.analytics.min.js';
       script.dataset.siteId = "1264";
       document.getElementsByTagName('head')[0].appendChild(script);
    </script>`;

    return (
        <div className="code">
            <div className="row">
                <div className="label">
                    <img src={gtm} alt="" />
                    Google Tag Manager (GTM)
                </div>
                <CopyTextButton text={conversionOptions.pixelScriptTag.value} size="medium" />
            </div>
            <div className="script-wrapper" style={{ fontSize: "12px" }}>
                {conversionOptions.pixelScriptTag.isLoading ? (
                    "Loading..."
                ) : (
                    <SyntaxHighlighter language="html" style={coy} showLineNumbers={true}>
                        {conversionOptions.pixelScriptTag.value}
                    </SyntaxHighlighter>
                )}
            </div>
        </div>
    );
};

const NextJSContent = () => {
    const { conversionOptions } = useGlobalContext();
    const nextjsLink = `<Script
       src="https://cdn.cookie3.co/scripts/analytics/0.11.4/cookie3.analytics.min.js"
       integrity="sha384-lzDmDdr/zEhMdlE+N04MgISCyL3RIWNCb9LjsrQeEFi8Gy5CKXIRI+u58ZV+ybYz"
       crossOrigin="anonymous"
       async
       strategy="lazyOnload"
       data-site-id="1264"
       data-chain-tracking-enabled="true"
    />`;

    return (
        <div className="code">
            <div className="row">
                <div className="label">
                    <img src={nextjs} alt="" />
                    _app.txt
                </div>
                <CopyTextButton text={conversionOptions.pixelScriptTag.value} size="medium" />
            </div>
            <div className="script-wrapper" style={{ fontSize: "12px" }}>
                {conversionOptions.pixelScriptTag.isLoading ? (
                    "Loading..."
                ) : (
                    <SyntaxHighlighter language="html" style={coy} showLineNumbers={true}>
                        {conversionOptions.pixelScriptTag.value}
                    </SyntaxHighlighter>
                )}
            </div>
        </div>
    );
};

const HTMLContent = () => {
    const { conversionOptions } = useGlobalContext();
    const htmlLink = `<script
        src="https://cdn.cookie3.co/scripts/analytics/0.11.4/cookie3.analytics.min.js"
        integrity="sha384-lzDmDdr/zEhMdlE+N04MgISCyL3RIWNCb9LjsrQeEFi8Gy5CKXIRI+u58ZV+ybYz"
        crossOrigin="anonymous"
        async="true"
        strategy="lazyOnload"
        data-site-id="1264"
        data-chain-tracking-enabled="true"
    ></script>`;

    return (
        <div className="code">
            <div className="row">
                <div className="label">
                    <img src={html} alt="" />
                    index.html
                </div>
                <CopyTextButton text={conversionOptions.pixelScriptTag.value} size="medium" />
            </div>
            <div className="script-wrapper" style={{ fontSize: "12px" }}>
                {conversionOptions.pixelScriptTag.isLoading ? (
                    "Loading..."
                ) : (
                    <SyntaxHighlighter language="html" style={coy} showLineNumbers={true}>
                        {conversionOptions.pixelScriptTag.value}
                    </SyntaxHighlighter>
                )}
            </div>
        </div>
    );
};

const NuxtContent = () => {
    const { conversionOptions } = useGlobalContext();
    const htmlLink = `<script
       src="https://cdn.cookie3.co/scripts/analytics/0.11.4/cookie3.analytics.min.js"
       integrity="sha384-lzDmDdr/zEhMdlE+N04MgISCyL3RIWNCb9LjsrQeEFi8Gy5CKXIRI+u58ZV+ybYz"
       crossOrigin="anonymous"
       async="true"
       strategy="lazyOnload"
       data-site-id="1264"
       data-chain-tracking-enabled="true"
    ></script>`;

    return (
        <div className="code">
            <div className="row">
                <div className="label">
                    <img src={nuxt} alt="" />
                    app.vue
                </div>
                <CopyTextButton text={conversionOptions.pixelScriptTag.value} size="medium" />
            </div>
            <div className="script-wrapper" style={{ fontSize: "12px" }}>
                {conversionOptions.pixelScriptTag.isLoading ? (
                    "Loading..."
                ) : (
                    <SyntaxHighlighter language="html" style={coy} showLineNumbers={true}>
                        {conversionOptions.pixelScriptTag.value}
                    </SyntaxHighlighter>
                )}
            </div>
        </div>
    );
};

const WordpressContent = () => {
    const { conversionOptions } = useGlobalContext();
    const htmlLink = `<script
       src="https://cdn.cookie3.co/scripts/analytics/0.11.4/cookie3.analytics.min.js"
       integrity="sha384-lzDmDdr/zEhMdlE+N04MgISCyL3RIWNCb9LjsrQeEFi8Gy5CKXIRI+u58ZV+ybYz"
       crossOrigin="anonymous"
       async="true"
       strategy="lazyOnload"
       data-site-id="1264"
       data-chain-tracking-enabled="true"
    ></script>`;

    return (
        <div className="code">
            <div className="row">
                <div className="label">
                    <img src={wordpress} alt="" />
                    functions.php
                </div>
                <CopyTextButton text={conversionOptions.pixelScriptTag.value} size="medium" />
            </div>
            <div className="script-wrapper" style={{ fontSize: "12px" }}>
                {conversionOptions.pixelScriptTag.isLoading ? (
                    "Loading..."
                ) : (
                    <SyntaxHighlighter language="html" style={coy} showLineNumbers={true}>
                        {conversionOptions.pixelScriptTag.value}
                    </SyntaxHighlighter>
                )}
            </div>
        </div>
    );
};

const PixelContent = () => {
    const {
        CreatePixelTagRequest,
        CreateConversionPixelRequest,
        conversionOptions,
        setConversionOptions,
        requestedData: {
            userProfileData: { currentOrganization },
        },
    } = useGlobalContext();

    return (
        <div className="code">
            <div className="row">
                <div className="label">
                    <img src={pixel} alt="" />
                    Tracking Pixel
                </div>
                <CopyTextButton text={conversionOptions.pixelScriptTag.value} size="medium" />
            </div>
            <div className="script-wrapper" style={{ fontSize: "12px" }}>
                {conversionOptions.pixelScriptTag.isLoading ? (
                    "Loading..."
                ) : (
                    <SyntaxHighlighter language="html" style={coy} showLineNumbers={true}>
                        {conversionOptions.pixelScriptTag.value}
                    </SyntaxHighlighter>
                )}
            </div>
        </div>
    );
};

const installPixelCodeTabs = [
    {
        title: "Google Tag Manager",
        startImageIcon: gtm,
        content: <GoogleTagManagerContent />,
        id: "tracking-1",
    },
    // {
    //     title: "Next.js",
    //     startImageIcon: nextjs,
    //     content: <NextJSContent />,
    //     id: "tracking-2",
    // },
    // {
    //     title: "HTML",
    //     startImageIcon: html,
    //     content: <HTMLContent />,
    //     id: "tracking-3",
    // },
    // {
    //     title: "Nuxt",
    //     startImageIcon: nuxt,
    //     content: <NuxtContent />,
    //     id: "tracking-4",
    // },
    // {
    //     title: "Wordpress",
    //     startImageIcon: wordpress,
    //     content: <WordpressContent />,
    //     id: "tracking-5",
    // },
    {
        title: "Tracking Pixel",
        startImageIcon: pixel,
        content: <PixelContent />,
        id: "tracking-6",
    },
];
