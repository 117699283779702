import React, { useEffect, useState, useRef } from "react";
import { Navbar, Button, Tabs, Modal, Carousel, MarketplaceCard, Breadcrumbs } from "../../../components";
import { motion } from "framer-motion";
import { useGlobalContext } from "../../../context";
import localData from "../../../localData";
import PublicationsTable from "./publications-table/PublicationsTable";
import { Link } from "react-router-dom";
import ShoppingPanel from "./shopping-panel/ShoppingPanel";
import Joyride, { STATUS } from "react-joyride";

const { edit, question, password, support, shoppingCart, heart } = localData.svgs;
const { productExample } = localData.images;

export default function PR() {
    useEffect(() => {
        document.title = "Marketplace";

        const metaDescription = document.querySelector('meta[name="description"]');
        metaDescription.content = "Markeplace description";
    }, []);
    const { fade } = useGlobalContext().animations;

    const [publicationsTabs, setPublicationsTabs] = useState([
        {
            startIcon: null,
            title: "All Packages",
            content: <PublicationsTable />,
        },
        {
            startIcon: null,
            title: "Tech 1",
            content: <PublicationsTable packageType="TechStartupsPackage1" />,
        },
        {
            startIcon: null,
            title: "Tech 2",
            content: <PublicationsTable packageType="TechStartupsPackage2" />,
        },
        {
            startIcon: null,
            title: "Crypto 1",
            content: <PublicationsTable packageType="CryptoPackage1" />,
        },
        {
            startIcon: null,
            title: "Crypto 2",
            content: <PublicationsTable packageType="CryptoPackage2" />,
        },
        {
            startIcon: null,
            title: "Crypto 3",
            content: <PublicationsTable packageType="CryptoPackage3" />,
        },
        {
            startIcon: null,
            title: "Gaming",
            content: <PublicationsTable packageType="GamingPackage" />,
        },
        {
            startIcon: null,
            title: "Finance 1",
            content: <PublicationsTable packageType="FinancePackage1" />,
        },
        {
            startIcon: null,
            title: "Finance 2",
            content: <PublicationsTable packageType="FinancePackage2" />,
        },
        {
            startIcon: null,
            title: "Press-Release 1",
            content: <PublicationsTable packageType="PressReleasePackage1" />,
        },
        {
            startIcon: null,
            title: "Press-Release 2",
            content: <PublicationsTable packageType="PressReleasePackage2" />,
        },
        {
            startIcon: null,
            title: "Crypto-Release 1",
            content: <PublicationsTable packageType="CryptoReleasesPack1" />,
        },
        {
            startIcon: null,
            title: "Crypto-Release 2",
            content: <PublicationsTable packageType="CryptoReleasesPack2" />,
        },
    ]);

    const { marketplaceState, requestedData } = useGlobalContext();

    const {
        activeMarketplaceDetails,
        displayMarketplaceDetailsModal,
        setDisplayMarketplaceDetailsModal,
        MarketplaceCardDetails,
        publications,
    } = marketplaceState;

    return (
        <motion.main {...fade} className="main marketplace-page">
            <PRJoyride />
            {/* <Navbar title="marketplace" /> */}
            <ShoppingPanel />
            {/* <Breadcrumbs items={[{ name: "PR" }]} /> */}
            <br />

            <FeaturedPackages />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />

            <h2 className="display-2">All Packages</h2>
            <br />

            <PublicationsTable />
            {/* <Tabs
                swiper={true}
                tabs={publicationsTabs}
                className="publications-tabs"
                callback={() => {}}
                isLoading={false}
            /> */}
            <Modal
                Toggler={() => {}}
                Child={MarketplaceCardDetails}
                display={displayMarketplaceDetailsModal}
                setDisplay={setDisplayMarketplaceDetailsModal}
                className="modal-dialog-centered  marketplace-details-modal"
            />
        </motion.main>
    );
}

const FeaturedPackages = () => {
    const { marketplaceState } = useGlobalContext();

    const { setActiveMarketplaceDetails, publications } = marketplaceState;

    const [filteredPublications, setFilteredPublications] = useState([]);

    useEffect(() => {
        const filteredItems = publications.filter((item) => item.isFeatured && item);
        setFilteredPublications(filteredItems);
    }, [publications]);

    return (
        <section className="featured-packages">
            <h2 className="featured-packages-title displaly-2">Most purchased packages </h2>

            <Carousel
                items={filteredPublications}
                Card={MarketplaceCard}
                cardOptions={{ readOnly: true, setActiveMarketplaceDetails }}
                options={{
                    spaceBetween: 10,
                    autoplay: false,
                    loop: false,
                    breakpoints: {
                        640: {
                            slidesPerView: 2,
                        },
                        960: {
                            slidesPerView: 3,
                        },
                        // 1100: {
                        //     slidesPerView: 3,
                        // },
                        // 1400: {
                        //     // slidesPerView: 4,
                        // },
                        // 1100: {
                        //     slidesPerView: 3,
                        // },
                        1400: {
                            slidesPerView: 4,
                        },
                    },
                }}
            />
        </section>
    );
};

const PRJoyride = () => {
    const defaultSteps = [
        {
            content: (
                <h2 className="joyride-subtitle">
                    Welcome to the PR tutorial. Choose and book PR services on various publications.
                </h2>
            ),
            locale: { skip: <strong>Skip</strong> },
            placement: "center",
            target: "body",
            title: "Welcome to PR Tutorial",
        },
        {
            content: (
                <h2 className="joyride-subtitle">
                    Choose the appropriate package. Read the list of publications carefully. Contact support
                    for help selecting the right package.
                </h2>
            ),
            locale: { skip: <strong>Skip</strong> },
            placement: "center",
            target: "body",
            title: "Choosing the Right Package",
        },
        {
            content: (
                <h2 className="joyride-subtitle">
                    Click the info button for additional details on PR packages, such as standard distribution
                    time.
                </h2>
            ),
            locale: { skip: <strong>Skip</strong> },
            placement: "bottom",
            target: "#pr-tutorial-3",
            title: "Viewing Additional Details",
        },
        {
            content: <h2 className="joyride-subtitle">Click the cart button to proceed to checkout.</h2>,
            locale: { skip: <strong>Skip</strong> },
            placement: "bottom",
            target: "#pr-tutorial-4",
            title: "Proceeding to Checkout",
        },
    ];

    const [{ run, steps }, setSteps] = useState({
        run: false,
        steps: defaultSteps,
    });

    const handleJoyrideCallback = (data) => {
        const { status, type, index } = data;

        if (status === "ready" || status === "finished") {
            let guideTutorials = JSON.parse(localStorage.getItem("guideTutorials"));
            if (!guideTutorials) return;
            guideTutorials = guideTutorials.filter((item) => item !== "prTutorial");
            localStorage.setItem("guideTutorials", JSON.stringify(guideTutorials));
        }
    };

    useEffect(() => {
        const guideTutorials = JSON.parse(localStorage.getItem("guideTutorials"));
        if (!guideTutorials || !guideTutorials.includes("prTutorial")) return;
        // setTimeout(() => {
        setSteps((prev) => ({ ...prev, run: true }));
        // }, 500);
    }, []);
    return (
        <Joyride
            continuous
            callback={handleJoyrideCallback}
            run={run}
            steps={steps}
            // hideBackButton
            // scrollToFirstStep
            showSkipButton
            showProgress
            disableBeacon
            disableScrolling={true}
            disableScrollParentFix={true}
            scrollOffset={200}
            locale={{
                last: "Complete",
            }}
            styles={{
                options: {
                    zIndex: 10000,
                },
            }}
        />
    );
};
