import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  BannerSlide,
  Carousel,
  Select as CustomSelect,
} from "../../components";

import { motion } from "framer-motion";
import { useGlobalContext } from "../../context";
import localData from "../../localData";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import AverageStatsChart from "./components/AverageStatsChart";
import AudiencesChart from "./components/AudiencesChart";
import AnalysisChart from "./components/AnalysisChart";
import AverageCosts from "./components/AverageCosts";

import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";

const endDate = new Date(); // today
const startDate = new Date(endDate);
startDate.setDate(endDate.getDate() - 6); // 7 days ago

const SELECTIONRANGE = {
  startDate,
  endDate,
  key: "selection",
};

const { edit, question, password, support, userShield, arrowLeftLong } =
  localData.svgs;
const {
  avatar,
  placeholder,
  gamingImage1,
  gamingImage2,
  gamingImage3,
  tradingImage1,
  tradingImage2,
  tradingImage3,
  web3CasinosImage1,
  web3CasinosImage2,
  web3CasinosImage3,
  deFiImage1,
  deFiImage2,
  deFiImage3,
  NFTImage1,
  NFTImage2,
  NFTImage3,
  preloaderBig,
} = localData.images;

// const verticalsData = {
//     blockchainGaming: [{ cover: gamingImage1 }, { cover: gamingImage2 }, { cover: gamingImage3 }],
//     cryptoTrading: [{ cover: tradingImage1 }, { cover: tradingImage2 }, { cover: tradingImage3 }],
//     web3Casinos: [{ cover: web3CasinosImage1 }, { cover: web3CasinosImage2 }, { cover: web3CasinosImage3 }],
//     deFi: [{ cover: deFiImage1 }, { cover: deFiImage2 }, { cover: deFiImage3 }],
//     NFT: [{ cover: NFTImage1 }, { cover: NFTImage2 }, { cover: NFTImage3 }],
// };

export default function Insights({ isPublic, mainContentRef }) {
  useEffect(() => {
    document.title = "Live Stats";

    const metaDescription = document.querySelector('meta[name="description"]');
    metaDescription.content =
      "Get Insights on Advertisers' Performance and Enhance Campaigns.";
  }, []);

  const { fade } = useGlobalContext().animations;
  const { _getInsightsImages } = useGlobalContext();

  const [dateRange, setDateRange] = useState([
    { title: "Last 7 Days", value: "7", isSelected: true, id: uuidv4() },
    { title: "Last 30 Days", value: "30", isSelected: false, id: uuidv4() },
    { title: "Last Quarter", value: "90", isSelected: false, id: uuidv4() },
  ]);
  const [categories, setCategories] = useState({});
  const [activeCategory, setActiveCategory] = useState("blockchainGaming");
  const [isDataLoading, setIsDataLoading] = useState(false);

  const getConvertedData = (data, DateData) => {
    const tempData = JSON.parse(data.substr(47).slice(0, -2));

    const headers = tempData.table.cols.map((col) => col.label);
    const rows = tempData.table.rows.map((row, index) => {
      const rowData = {};
      if (DateData) rowData.Date = DateData[index]?.Date;
      row.c.forEach((cell, index) => {
        rowData[headers[index]] = cell ? cell.f : null;
      });
      return rowData;
    });

    return rows;
  };

  useEffect(() => {
    const fetch = async () => {
      setIsDataLoading(true);
      try {
        const url =
          "https://docs.google.com/spreadsheets/d/1ueISIkjl4q0SfEokjUvdgsBN8dgR96VEGRC2-wfGDXY/gviz/tq?sheet=";

        const AllVerticalsTitle = "All Verticals";
        const Web3CasinosTitle = "Web3 Casinos";
        const BlockchainGamingTitle = "Blockchain Gaming";
        const CryptoTradingTitle = "Crypto Trading";
        const DeFiTitle = "DeFi";
        const NFTTitle = "NFT";
        const InfrastructureTitle = "Infrastructure";
        const TokenLaunchesTitle = "Token Launches";

        const urls = [
          // ALL VERTICALS
          url + AllVerticalsTitle + "&range=A2:A248", // Date

          // WEB 3 CASINO
          url + Web3CasinosTitle + "&range=A2:F248", // Web3CasinoTotal
          url + Web3CasinosTitle + "&range=H2:L248", // Web3CasinoUsers
          url + Web3CasinosTitle + "&range=N2:R248", // SportsBettingUsers
          url + Web3CasinosTitle + "&range=T2:X248", // P2EGamers
          url + Web3CasinosTitle + "&range=Z2:AD248", // Web3CasinosPowerUsers
          url + Web3CasinosTitle + "&range=AF2:AJ248", // PokerUsers
          url + Web3CasinosTitle + "&range=AL2:AP248", // GamingTokenHolders

          // BLOCKCHAIN GAMING
          url + BlockchainGamingTitle + "&range=A2:F248", // TokenTradersTotal
          url + BlockchainGamingTitle + "&range=H2:L248", // TokenTradersGaming
          url + BlockchainGamingTitle + "&range=N2:R248", // GameFitokenholders
          url + BlockchainGamingTitle + "&range=T2:X248", // P2EGamers
          url + BlockchainGamingTitle + "&range=Z2:AD248", // Web3CasinosPowerUsers
          url + BlockchainGamingTitle + "&range=AF2:AJ248", // PokerUsers
          url + BlockchainGamingTitle + "&range=AL2:AP248", // Web3NativesGAMING

          // CRYPTO TRADING
          url + CryptoTradingTitle + "&range=A2:F248", // CryptoTradingTotal
          url + CryptoTradingTitle + "&range=H2:L248", // Web3Natives
          url + CryptoTradingTitle + "&range=N2:R248", // CryptoTraders
          url + CryptoTradingTitle + "&range=T2:X248", // ICOIDOParticipants
          url + CryptoTradingTitle + "&range=Z2:AD248", // BTCETHwhales
          url + CryptoTradingTitle + "&range=AF2:AJ248", // StableCoinUsers
          url + CryptoTradingTitle + "&range=AL2:AP248", // CryptoNews

          // DeFi
          url + DeFiTitle + "&range=A2:F248", // DeFiTotal
          url + DeFiTitle + "&range=H2:L248", // DeFiTokensHolder
          url + DeFiTitle + "&range=N2:R248", // DeFiUsers
          url + DeFiTitle + "&range=T2:X248", // DEXUsers
          url + DeFiTitle + "&range=Z2:AD248", // CEXUsers
          url + DeFiTitle + "&range=AF2:AJ248", // FinanceUsers
          url + DeFiTitle + "&range=AL2:AP248", // DeFiNews

          // NFT/Meme
          url + NFTTitle + "&range=A2:F248", // NFTTotal
          url + NFTTitle + "&range=H2:L248", // NFTCollectors
          url + NFTTitle + "&range=N2:R248", // NFTTraders
          url + NFTTitle + "&range=T2:X248", // Flippers
          url + NFTTitle + "&range=Z2:AD248", // NFTWhales
          url + NFTTitle + "&range=AF2:AJ248", // MarketplaceUsers
          url + NFTTitle + "&range=AL2:AP248", // MemeTokenTraders

          // Infrastructure
          url + InfrastructureTitle + "&range=A2:F248", // InfrastructureTotal
          url + InfrastructureTitle + "&range=H2:L248", // L1L2Users
          url + InfrastructureTitle + "&range=N2:R248", // L1Tokenholders
          url + InfrastructureTitle + "&range=T2:X248", // BTCETHWhales
          url + InfrastructureTitle + "&range=Z2:AD248", // CryptoNews2
          url + InfrastructureTitle + "&range=AF2:AJ248", // DexUsers
          url + InfrastructureTitle + "&range=AL2:AP248", // TokenTraders

          // Token Launches
          url + TokenLaunchesTitle + "&range=A2:F248", // TokenLaunchesTotal
          url + TokenLaunchesTitle + "&range=H2:L248", // NFTCollectors2
          url + TokenLaunchesTitle + "&range=N2:R248", // IDOLaunchpadsTokenHolders
          url + TokenLaunchesTitle + "&range=T2:X248", // IDOsParticipants
          url + TokenLaunchesTitle + "&range=Z2:AD248", // BTCETHWhales2
          url + TokenLaunchesTitle + "&range=AF2:AJ248", // MarketplaceUsers2
          url + TokenLaunchesTitle + "&range=AL2:AP248", // SOLTokenTraders
        ];

        const [
          // ALL VERTICALS
          Date,

          // WEB 3 CASINOS
          Web3CasinoTotal,
          Web3CasinoUsers,
          SportsBettingUsers,
          P2EGamers,
          Web3CasinosPowerUsers,
          PokerUsers,
          GamingTokenHolders,

          // BLOCKCHAIN GAMING
          TokenTradersTotal,
          TokenTradersGaming,
          GameFitokenholders,
          Web3Gamers,
          P2EGamers2,
          TokenTradersGaming2,
          Web3NativesGAMING,

          // CRYPTO TRADING
          CryptoTradingTotal,
          Web3Natives,
          CryptoTraders,
          ICOIDOParticipants,
          BTCETHwhales,
          StableCoinUsers,
          CryptoNews,

          // DeFi
          DeFiTotal,
          DeFiTokensHolder,
          DeFiUsers,
          DEXUsers,
          CEXUsers,
          FinanceUsers,
          DeFiNews,

          // NFT/Meme
          NFTTotal,
          NFTCollectors,
          NFTTraders,
          Flippers,
          NFTWhales,
          MarketplaceUsers,
          MemeTokenTraders,

          // Infrastructure
          InfrastructureTotal,
          L1L2Users,
          L1Tokenholders,
          BTCETHWhales,
          CryptoNews2,
          DexUsers,
          TokenTraders,

          // Token Launches
          TokenLaunchesTotal,
          NFTCollectors2,
          IDOLaunchpadsTokenHolders,
          IDOsParticipants,
          BTCETHWhales2,
          MarketplaceUsers2,
          SOLTokenTraders,
        ] = await Promise.all(urls.map((url) => axios(url)));
        const DateData = getConvertedData(Date.data);

        const tempCategories = {
          // WEB 3 CASINO
          web3Casinos: {
            TotalData: {
              name: "Web3 Casino Total",
              data: getConvertedData(Web3CasinoTotal.data, DateData),
            },
            Web3CasinoUsersData: {
              name: "Web3 Casino Users",
              data: getConvertedData(Web3CasinoUsers.data, DateData),
            },
            SportsBettingUsersData: {
              name: "Sports Betting Users",
              data: getConvertedData(SportsBettingUsers.data, DateData),
            },
            P2EGamersData: {
              name: "P2E Gamers",
              data: getConvertedData(P2EGamers.data, DateData),
            },
            Web3CasinosPowerUsersData: {
              name: "Web3 Casinos Power Users",
              data: getConvertedData(Web3CasinosPowerUsers.data, DateData),
            },
            PokerUsersData: {
              name: "Poker Users",
              data: getConvertedData(PokerUsers.data, DateData),
            },
            GamingTokenHoldersData: {
              name: "Gaming Token Holders",
              data: getConvertedData(GamingTokenHolders.data, DateData),
            },
          },

          // BLOCKCHAIN GAMING
          blockchainGaming: {
            TotalData: {
              name: "Token Traders Total",
              data: getConvertedData(TokenTradersTotal.data, DateData),
            },
            TokenTradersGamingData: {
              name: "Token Traders Gaming",
              data: getConvertedData(TokenTradersGaming.data, DateData),
            },
            GameFitokenholdersData: {
              name: "GameFi Token Holders",
              data: getConvertedData(GameFitokenholders.data, DateData),
            },
            Web3GamersData: {
              name: "Web3 Gamers",
              data: getConvertedData(Web3Gamers.data, DateData),
            },
            P2EGamers2Data: {
              name: "P2E Gamers",
              data: getConvertedData(P2EGamers2.data, DateData),
            },
            TokenTradersGaming2Data: {
              name: "Token Traders Gaming",
              data: getConvertedData(TokenTradersGaming2.data, DateData),
            },
            Web3NativesGAMINGData: {
              name: "Web3 Natives",
              data: getConvertedData(Web3NativesGAMING.data, DateData),
            },
          },

          // CRYPTO TRADING
          cryptoTrading: {
            TotalData: {
              name: "Crypto Trading Total",
              data: getConvertedData(CryptoTradingTotal.data, DateData),
            },
            Web3NativesData: {
              name: "Web3 Natives",
              data: getConvertedData(Web3Natives.data, DateData),
            },
            CryptoTradersData: {
              name: "Crypto Traders",
              data: getConvertedData(CryptoTraders.data, DateData),
            },
            ICOIDOParticipantsData: {
              name: "ICO IDO Participants",
              data: getConvertedData(ICOIDOParticipants.data, DateData),
            },
            BTCETHwhalesData: {
              name: "BTC/ETH whales",
              data: getConvertedData(BTCETHwhales.data, DateData),
            },
            StableCoinUsersData: {
              name: "Stable Coin Users",
              data: getConvertedData(StableCoinUsers.data, DateData),
            },
            CryptoNewsData: {
              name: "Crypto News",
              data: getConvertedData(CryptoNews.data, DateData),
            },
          },

          // DeFi
          deFi: {
            TotalData: {
              name: "DeFi Total",
              data: getConvertedData(DeFiTotal.data, DateData),
            },
            DeFiTokensHolder: {
              name: "DeFi Tokens- Holder",
              data: getConvertedData(DeFiTokensHolder.data, DateData),
            },
            DeFiUsersData: {
              name: "DeFi Users",
              data: getConvertedData(DeFiUsers.data, DateData),
            },
            DEXUsersData: {
              name: "DEX Users",
              data: getConvertedData(DEXUsers.data, DateData),
            },
            CEXUsersData: {
              name: "CEX Users",
              data: getConvertedData(CEXUsers.data, DateData),
            },
            FinanceUsersData: {
              name: "Finance Users",
              data: getConvertedData(FinanceUsers.data, DateData),
            },
            DeFiNewsData: {
              name: "DeFi News",
              data: getConvertedData(DeFiNews.data, DateData),
            },
          },

          // NFT/Meme
          NFT: {
            TotalData: {
              name: "NFT Total",
              data: getConvertedData(NFTTotal.data, DateData),
            },
            NFTCollectorsData: {
              name: "NFT Collectors",
              data: getConvertedData(NFTCollectors.data, DateData),
            },
            NFTTradersData: {
              name: "NFT Traders",
              data: getConvertedData(NFTTraders.data, DateData),
            },
            FlippersData: {
              name: "Flippers",
              data: getConvertedData(Flippers.data, DateData),
            },
            NFTWhalesData: {
              name: "NFT Whales",
              data: getConvertedData(NFTWhales.data, DateData),
            },
            MarketplaceUsersData: {
              name: "Marketplace Users",
              data: getConvertedData(MarketplaceUsers.data, DateData),
            },
            MemeTokenTradersData: {
              name: "Meme Token Traders",
              data: getConvertedData(MemeTokenTraders.data, DateData),
            },
          },

          // Infrastructure
          infrastructure: {
            TotalData: {
              name: "Infrastructure Total",
              data: getConvertedData(InfrastructureTotal.data, DateData),
            },
            L1L2UsersData: {
              name: "L1 L2 Users",
              data: getConvertedData(L1L2Users.data, DateData),
            },
            L1TokenholdersData: {
              name: "L1 Token holders",
              data: getConvertedData(L1Tokenholders.data, DateData),
            },
            BTCETHWhalesData: {
              name: "BTC/ETH whales",
              data: getConvertedData(BTCETHWhales.data, DateData),
            },
            CryptoNews2Data: {
              name: "Crypto News",
              data: getConvertedData(CryptoNews2.data, DateData),
            },
            DexUsersData: {
              name: "Dex Users",
              data: getConvertedData(DexUsers.data, DateData),
            },
            TokenTradersData: {
              name: "Token Traders",
              data: getConvertedData(TokenTraders.data, DateData),
            },
          },

          // Token Launches
          tokenLaunches: {
            TotalData: {
              name: "Infrastructure Total",
              data: getConvertedData(TokenLaunchesTotal.data, DateData),
            },
            NFTCollectors2Data: {
              name: "NFT Collectors",
              data: getConvertedData(NFTCollectors2.data, DateData),
            },
            IDOLaunchpadsTokenHoldersData: {
              name: "IDO Launchpads Holders",
              data: getConvertedData(IDOLaunchpadsTokenHolders.data, DateData),
            },
            IDOsParticipantsData: {
              name: "IDOs Participants",
              data: getConvertedData(IDOsParticipants.data, DateData),
            },
            BTCETHWhales2Data: {
              name: "BTC/ETH Whales",
              data: getConvertedData(BTCETHWhales2.data, DateData),
            },
            MarketplaceUsers2Data: {
              name: "Marketplace Users",
              data: getConvertedData(MarketplaceUsers2.data, DateData),
            },
            SOLTokenTradersData: {
              name: "SOL Token Traders",
              data: getConvertedData(SOLTokenTraders.data, DateData),
            },
          },
        };
        setCategories(tempCategories);
      } catch (err) {
        console.error(err);
      }
      setIsDataLoading(false);
    };
    fetch();
  }, []);

  const [categoryItems, setCategoryItems] = useState([
    {
      startIcon: null,
      title: "Blockchain Gaming",
      value: "blockchainGaming",
      active: true,
    },
    // {
    //     startIcon: null,
    //     title: "Crypto Trading",
    //     value: "cryptoTrading",
    //     // disabled: true,
    // },
    // {
    //     startIcon: null,
    //     title: "web3 Casinos",
    //     value: "web3Casinos",
    // },
    {
      startIcon: null,
      title: "DeFi",
      value: "deFi",
    },
    {
      startIcon: null,
      title: "NFT/Meme",
      value: "NFT",
    },
    {
      startIcon: null,
      title: "Infrastructure",
      value: "infrastructure",
    },
    {
      startIcon: null,
      title: "Token Launches",
      value: "tokenLaunches",
    },
  ]);

  const [verticalsData, setVerticalsData] = useState({
    blockchainGaming: [
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/Gaming/Aradena Games.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/Gaming/Excelsior.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/Gaming/Gala.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/Gaming/Gamety.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/Gaming/Reel33.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/Gaming/Telefriens.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/Gaming/Virtual Versions.gif",
    ],
    NFT: [
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/NFTs/Metamorphoses.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/NFTs/Paras Comic.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/NFTs/Paras Marketplace.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/NFTs/Raremint.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/NFTs/Space Metaverse.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/NFTs/XTN3GZ.gif",
    ],
    web3Casinos: [
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/Web3 Casinos/02 HQ(1).gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/Web3 Casinos/02 HQ(2).gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/Web3 Casinos/02 HQ.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/Web3 Casinos/4 HQ.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/Web3 Casinos/Bet.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/Web3 Casinos/Betplay.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/Web3 Casinos/Cryptorush.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/Web3 Casinos/Dafabet.jpeg",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/Web3 Casinos/Roobet.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/Web3 Casinos/Stake.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/Web3 Casinos/Vbet.gif",
    ],
    cryptoTrading: [
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/Trading/Alpha Pro.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/Trading/Bomb Money.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/Trading/Carbon Browser.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/Trading/Coinbase.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/Trading/DogeVision.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/Trading/EstateX.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/Trading/Etuktuk.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/Trading/FXTM.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/Trading/Monk Coin.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/Trading/Nebeus.jpeg",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/Trading/NextGem.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/Trading/OKX.png",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/Trading/Optimus Ventures.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/Trading/Palmswap.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/Trading/Starchain.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/Trading/WorkX.gif",
    ],
    deFi: [
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/DeFi/BettingCroc.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/DeFi/BlockDAG.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/DeFi/Bossie.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/DeFi/Compound.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/DeFi/Defiway.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/DeFi/E-Money.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/DeFi/Layer3.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/DeFi/Mercado Bitcoin.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/DeFi/Ridian.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/DeFi/RocketX.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/DeFi/Rubic Finance.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/DeFi/Telegram.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/DeFi/UNCX.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/DeFi/Valuit.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/DeFi/Xodex.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/DeFi/brainedge.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/DeFi/zkEra.gif",
    ],
    tokenLaunches: [
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/Token Launches/ 8.Gamety.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/Token Launches/1. Carbon Browser.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/Token Launches/10. E-Money.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/Token Launches/11. Aradena Games.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/Token Launches/12. Etuktuk.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/Token Launches/13. Optimus Ventures.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/Token Launches/2. Layer3.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/Token Launches/3. Palmswap.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/Token Launches/4. WorkX.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/Token Launches/5. Excelsior.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/Token Launches/6. Bomb Money.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/Token Launches/7. Monk Coin.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/Token Launches/8. Virtual Versions.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/Token Launches/9. EstateX.gif",
    ],
    infrastructure: [
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/Infrastructure/1. Carbon Browser.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/Infrastructure/2. Rubic Finance.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/Infrastructure/3. UNCX.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/Infrastructure/4. brainedge.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/Infrastructure/5. Starchain.gif",
      "https://storage.googleapis.com/web3-marketing-hub.appspot.com/insights-images/Infrastructure/6. Xodex.gif",
    ],
  });

  // useEffect(() => {
  //     _getInsightsImages({
  //         callback: (data) => {
  //             console.log(data, "here");
  //             // setImageSrc('')
  //             setVerticalsData(data);
  //         },
  //     });
  // }, []);

  const [selectedDateRange, setSelectedDateRange] = useState("");
  const [isScrolled, setIsScrolled] = useState(false);

  const handleChange = (event) => {
    const value = event.target.value;
    const updatedItems = categoryItems.map((item) => ({
      ...item,
      active: item.value === value,
    }));
    setCategoryItems(updatedItems);
    setActiveCategory(value);
  };

  const [selectedDate, setSelectedDate] = useState("7")

  const handleDateChange = (event) => {
    const selectedValue = event.target.value;
    const updatedDateRange = dateRange.map(item => ({
      ...item,
      isSelected: item.value === selectedValue,
    }));
    setDateRange(updatedDateRange);
    setSelectedDate(selectedValue);
  };

  useEffect(() => {
    const handleScroll = () => {
        if (mainContentRef?.current) {
            const scrollTop = mainContentRef.current.scrollTop;
            console.log("Scroll Top:", scrollTop);

            if (scrollTop > 0) {
                setIsScrolled(true);  
            } else {
                setIsScrolled(false);
            }
        }
    };

    if (mainContentRef?.current) {
        mainContentRef.current.addEventListener('scroll', handleScroll);
    }

    return () => {
        if (mainContentRef?.current) {
            mainContentRef.current.removeEventListener('scroll', handleScroll);
        }
    };
}, [mainContentRef]);


  return (
    <motion.main {...fade} className="main">
        <h2 className="main-title-insights">Current Ad Network Performance</h2>
        <div className={`verticals-container ${isScrolled ? "scrolled" : ""}`}>
        <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
          <h5 className="select-vertical-label">Select Vertical</h5>
          {/* <InputLabel variant="standard" htmlFor="uncontrolled-native">
            Select Vertical
          </InputLabel> */}
          <Select value={activeCategory} onChange={handleChange}>
            {categoryItems.map((item, index) => (
              <MenuItem
                key={index}
                value={item.value}
              >
                {item.title === "cost" ? "Avg. Conversion Cost" : item.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {/* <div className="scroll-items">
                    <Carousel
                        items={categoryItems}
                        Card={(item, index) => {
                            return (
                                <Button
                                    // size="sm"
                                    color={` ${item.active ? "btn-success" : ""}`}
                                    onClick={() => {
                                        let items = [...categoryItems];
                                        items = items.map((category) => ({
                                            ...category,
                                            active: category.value == item.value,
                                        }));
                                        setCategoryItems(items);
                                        setActiveCategory(item.value);
                                    }}
                                    disabled={item.disabled}
                                >
                                    {item.title}
                                </Button>
                            );
                        }}
                        options={{
                            slidesPerView: "auto",
                            breakpoints: null,
                            loop: false,
                            autoplay: false,
                            speed: 500,
                            spaceBetween: 3,
                        }}
                    />
                </div> */}
        {/* <CustomSelect
          {...{
            items: dateRange,
            setItems: setDateRange,
            placeholder: "Select Date",
            variant: "",
            color: "dark",
            className: "insights-categories",
            isLoading: false,
            callback: (item) => {
              let range = null;
              if (item.title == "Last 7 Days") range = 7;
              if (item.title == "Last 30 Days") range = 30;
              if (item.title == "Last Quarter") range = null;
            },
          }}
        /> */}
        <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }} className="timeframe-selector">
          <InputLabel variant="standard" htmlFor="uncontrolled-native">
            Select Timeframe
          </InputLabel>
          <Select value={selectedDate} onChange={handleDateChange}>
            {dateRange.map((item, index) => (
              <MenuItem
                key={index}
                value={item.value}
              >
                {item.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div className="insights-container">
        <div className="insights-page-content">
          <div style={{ marginBottom: "4rem" }}>
            <AverageStatsChart {...{ categories, activeCategory, dateRange }} />
          </div>
          <div style={{ marginBottom: "4rem" }}>
            <AudiencesChart {...{ categories, activeCategory, dateRange }} />
          </div>
          <div>
            <AverageCosts {...{ categories, activeCategory, dateRange }} />
          </div>
          <div className="insights-row">
            <div className="chart-conatiner">
              <AnalysisChart {...{ categories, activeCategory, dateRange }} />
            </div>
            <div className="wrapper">
              <h3 className="banner-slide-title">Top Performing Ads</h3>
              <br />
              <BannerSlide data={verticalsData[activeCategory]} />
            </div>
          </div>
        </div>
      </div>
    </motion.main>
  );
}
