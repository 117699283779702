import React, { useState, useRef } from "react";
import { v4 as uuidv4 } from "uuid";
import localData from "../../localData";
import { useGlobalContext } from "../../context";
import { useStateContext } from "../../stateContext";
import { Button, UploadCard, Modal } from "../../components";
import useUtil from "../../hooks/useUtil";

export default function UploadImage({
    selectedImages,
    setSelectedImages,
    disabled,
    image,
    setImage,
    setFile,
    setBuiltGifImage
}) {
    const { placeholder } = localData.images;
    const { errorAlert, isDarkModeEnabled, campaignsState } = useGlobalContext();
    const { setDisplayCreativesModal, creativesState, setCreativesState } = campaignsState;
    const inputRef = useRef();

    const convertBase64 = async (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = () => resolve(fileReader.result);
            fileReader.onerror = (error) => reject(error);
        });
    };

    const handleGifImage = async (file) => {
        const base64Image = await convertBase64(file);
        const image = new Image();
        image.src = base64Image;
        image.onload = (e) => {
            let item = {
                file,
                preview: URL.createObjectURL(file),
                image: base64Image,
                id: uuidv4(),
                status: 1,
                title: file.name,
                size: file.size,
                type: file.type,
            };
            setImage(base64Image);
            setBuiltGifImage(item);
        };
    };

    const { getFileFromCanvas, resizeImage } = useUtil();

    const handleFileChange = async (file) => {
        if (!file) return;
        if (file.type === "image/gif") {
            handleGifImage(file);
            return;
        }
        const resizedDataURL = await resizeImage(file, 1080, 720);
        let image64 = await convertBase64(resizedDataURL);
        setCreativesState((prev) => ({
            ...prev,
            uploadedImage: image64,
        }));
        setDisplayCreativesModal("block");
    };

    const handleFileDrop = (e) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        if (file) {
            handleFileChange(file);
        }
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDragEnter = (e) => {
        e.preventDefault();
    };

    return (
        <>
            <div
                onDragOver={handleDragOver}
                onDragEnter={handleDragEnter}
                onDrop={handleFileDrop}
                className={`upload-image drag-drop-container ${disabled ? "on-hold" : ""}`}
            >
                <input
                    ref={inputRef}
                    type="file"
                    accept="image/gif, image/jpeg, image/png"
                    className="file-input"
                    onChange={(e) => handleFileChange(e.target.files[0])}
                    style={{ display: "none" }}
                />
                <div
                    className="wrapper"
                    onClick={() => inputRef.current.click()}
                    data-guide="upload-ads-tutorial-1"
                >
                    <div className="image-wrapper">
                        <img className={`file-image`} src={placeholder} />
                    </div>
                    <strong>
                        Drag your files here, or <span className="text-primary">browse</span>
                    </strong>
                    <p className="info">
                        Format: (GIF, PNG, JPG)
                        <br />
                        Ad Sizes: (900x600, 300x250, 320x100, 728x90, 320x50) 
                        {/* <br /> Recommended size is 900 x 600. */}
                    </p>
                </div>
            </div>
        </>
    );
}
